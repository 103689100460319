//! Common
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_SOCKET_CONNECTION_STATUS = 'SET_SOCKET_CONNECTION_STATUS';
export const OPEN_DOWNLOAD_OUR_APP_MODAL = 'OPEN_DOWNLOAD_OUR_APP_MODAL';
export const CLOSE_DOWNLOAD_OUR_APP_MODAL = 'CLOSE_DOWNLOAD_OUR_APP_MODAL';

//! Auth
//* Customer Auth
export const CUSTOMER_LOGIN = 'CUSTOMER_LOGIN';
export const CUSTOMER_LOGIN_OTP = 'CUSTOMER_LOGIN_OTP';
export const CUSTOMER_LOGIN_INPUT_FIELD = 'CUSTOMER_LOGIN_INPUT_FIELD';
export const CUSTOMER_UPDATE_PROFILE = 'CUSTOMER_UPDATE_PROFILE';
export const CUSTOMER_CHANGE_PICTURE = 'CUSTOMER_CHANGE_PICTURE';

//* Astrologer Auth
export const ASTROLOGER_LOGIN = 'ASTROLOGER_LOGIN';

//* Logout 
export const USER_LOGOUT = 'USER_LOGOUT';

//! User 
export const GET_USER_CUSTOMER_BY_ID = 'GET_USER_CUSTOMER_BY_ID';
export const SET_USER_CUSTOMER_BY_ID = 'SET_USER_CUSTOMER_BY_ID';
export const RECHARGE_USER_CUSTOMER_WALLET = 'RECHARGE_USER_CUSTOMER_WALLET';
export const GET_USER_CUSTOMER_WALLET_HISTORY = 'GET_USER_CUSTOMER_WALLET_HISTORY';
export const SET_USER_CUSTOMER_WALLET_HISTORY = 'SET_USER_CUSTOMER_WALLET_HISTORY';
export const GET_USER_CUSTOMER_TRANSACTION_HISTORY = 'GET_USER_CUSTOMER_TRANSACTION_HISTORY';
export const SET_USER_CUSTOMER_TRANSACTION_HISTORY = 'SET_USER_CUSTOMER_TRANSACTION_HISTORY';
export const GET_USER_CUSTOMER_ORDER_HISTORY = 'GET_USER_CUSTOMER_ORDER_HISTORY';
export const SET_USER_CUSTOMER_ORDER_HISTORY = 'SET_USER_CUSTOMER_ORDER_HISTORY';
export const GET_USER_CUSTOMER_PUJA_BOOK_HISTORY = 'GET_USER_CUSTOMER_PUJA_BOOK_HISTORY';
export const SET_USER_CUSTOMER_PUJA_BOOK_HISTORY = 'SET_USER_CUSTOMER_PUJA_BOOK_HISTORY';
export const GET_USER_CUSTOMER_ADDRESS = 'GET_USER_CUSTOMER_ADDRESS';
export const SET_USER_CUSTOMER_ADDRESS = 'SET_USER_CUSTOMER_ADDRESS';
export const CREATE_USER_CUSTOMER_ADDRESS = 'CREATE_USER_CUSTOMER_ADDRESS';
export const UPDATE_USER_CUSTOMER_ADDRESS = 'UPDATE_USER_CUSTOMER_ADDRESS';
export const DELETE_USER_CUSTOMER_ADDRESS = 'DELETE_USER_CUSTOMER_ADDRESS';

export const GET_USER_ASTROLOGER_BY_ID = 'GET_USER_ASTROLOGER_BY_ID';
export const SET_USER_ASTROLOGER_BY_ID = 'SET_USER_ASTROLOGER_BY_ID';
export const CHANGE_USER_ASTROLOGER_CHAT_STATUS = 'CHANGE_USER_ASTROLOGER_CHAT_STATUS';
export const CHANGE_USER_ASTROLOGER_CALL_STATUS = 'CHANGE_USER_ASTROLOGER_CALL_STATUS';
export const CHANGE_USER_ASTROLOGER_VIDEO_CALL_STATUS = 'CHANGE_USER_ASTROLOGER_VIDEO_CALL_STATUS';
export const USER_ASTROLOGER_WITHDRAWAL_REQUEST = 'USER_ASTROLOGER_WITHDRAWAL_REQUEST';
export const GET_USER_ASTROLOGER_WALLET_HISTORY = 'GET_USER_ASTROLOGER_WALLET_HISTORY';
export const SET_USER_ASTROLOGER_WALLET_HISTORY = 'SET_USER_ASTROLOGER_WALLET_HISTORY';
export const GET_USER_ASTROLOGER_TRANSACTION_HISTORY = 'GET_USER_ASTROLOGER_TRANSACTION_HISTORY';
export const SET_USER_ASTROLOGER_TRANSACTION_HISTORY = 'SET_USER_ASTROLOGER_TRANSACTION_HISTORY';
export const GET_USER_ASTROLOGER_REGISTERED_PUJA_HISTORY = 'GET_USER_ASTROLOGER_REGISTERED_PUJA_HISTORY';
export const SET_USER_ASTROLOGER_REGISTERED_PUJA_HISTORY = 'GET_USER_ASTROLOGER_REGISTERED_PUJA_HISTORY';
export const GET_USER_ASTROLOGER_BOOKED_PUJA_HISTORY = 'GET_USER_ASTROLOGER_BOOKED_PUJA_HISTORY';
export const SET_USER_ASTROLOGER_BOOKED_PUJA_HISTORY = 'GET_USER_ASTROLOGER_BOOKED_PUJA_HISTORY';
export const COMPLETE_BOOKED_PUJA_HISTORY = 'COMPLETE_BOOKED_PUJA_HISTORY';

//! Astrologer
export const GET_ASTROLOGER = 'GET_ASTROLOGER';
export const SET_ASTROLOGER = 'SET_ASTROLOGER';
export const GET_ASTROLOGER_BY_ID = 'GET_ASTROLOGER_BY_ID';
export const SET_ASTROLOGER_BY_ID = 'SET_ASTROLOGER_BY_ID';
export const GET_ASTROLOGER_REVIEW_BY_ID = 'GET_ASTROLOGER_REVIEW_BY_ID';
export const SET_ASTROLOGER_REVIEW_BY_ID = 'SET_ASTROLOGER_REVIEW_BY_ID';

// TODO : Chat
export const GET_LINKED_PROFILE_FOR_CHAT = 'GET_LINKED_PROFILE_FOR_CHAT';
export const SET_LINKED_PROFILE_FOR_CHAT = 'SET_LINKED_PROFILE_FOR_CHAT';
// export const CREATE_PROFILE_FOR_CHAT = 'CREATE_PROFILE_FOR_CHAT';
// export const INITIATE_CHAT_MESSAGE = 'INITIATE_CHAT_MESSAGE';
export const CHAT_REQUEST_SEND_BY_CUSTOMER = 'CHAT_REQUEST_SEND_BY_CUSTOMER';
export const CHAT_REQUEST_ACCEPT_REJECT_BY_ASTROLOGER = 'CHAT_REQUEST_ACCEPT_REJECT_BY_ASTROLOGER';
export const CHAT_REQUEST_ACCEPT_REJECT_BY_CUSTOMER = 'CHAT_REQUEST_ACCEPT_REJECT_BY_CUSTOMER';
export const SET_CHAT_TIMER_COUNTDOWN = 'SET_CHAT_TIMER_COUNTDOWN';
export const END_CHAT_MESSAGE = 'END_CHAT_MESSAGE';
export const CLOSE_CHAT_MESSAGE = 'CLOSE_CHAT_MESSAGE';
export const HIDE_CHAT_MESSAGE_INPUT_FIELD = 'HIDE_CHAT_MESSAGE_INPUT_FIELD';

//* Rest 
export const SEND_CHAT_MESSAGE = 'SEND_CHAT_MESSAGE';
export const GET_CHAT_MESSAGE = 'GET_CHAT_MESSAGE';
export const SET_CHAT_MESSAGE = 'SET_CHAT_MESSAGE';

//* Chat Invoice 
export const GET_CHAT_INVOICE_DATA = 'GET_CHAT_INVOICE_DATA';
export const SET_CHAT_INVOICE_DATA = 'SET_CHAT_INVOICE_DATA';

export const GET_CHAT_INVOICE_VISIBILITY = 'GET_CHAT_INVOICE_VISIBILITY';
export const SET_CHAT_INVOICE_VISIBILITY = 'SET_CHAT_INVOICE_VISIBILITY';

export const GET_ASTROLOGER_CHAT_INVOICE_VISIBILITY = 'GET_ASTROLOGER_CHAT_INVOICE_VISIBILITY';
export const SET_ASTROLOGER_CHAT_INVOICE_VISIBILITY = 'SET_ASTROLOGER_CHAT_INVOICE_VISIBILITY';

// TODO : Call
export const CALL_INTAKE_DETAIL_DATA = 'CALL_INTAKE_DETAIL_DATA';

//* Call Invoice 
export const GET_CALL_INVOICE_DATA = 'GET_CALL_INVOICE_DATA';
export const SET_CALL_INVOICE_DATA = 'SET_CALL_INVOICE_DATA';

export const GET_CALL_INVOICE_VISIBILITY = 'GET_CALL_INVOICE_VISIBILITY';
export const SET_CALL_INVOICE_VISIBILITY = 'SET_CALL_INVOICE_VISIBILITY';

//* Rating 
export const GET_ASTROLOGER_RATING_VISIBILITY = 'GET_ASTROLOGER_RATING_VISIBILITY';
export const SET_ASTROLOGER_RATING_VISIBILITY = 'SET_ASTROLOGER_RATING_VISIBILITY';

//? Chat Request
export const REQUEST_INITIATED_BY_CUSTOMER = 'REQUEST_INITIATED_BY_CUSTOMER ';
export const REJECT_CHAT_BY_ASTROLOGER = 'REJECT_CHAT_BY_ASTROLOGER ';

//! Kundli
export const GET_KUNDLI = 'GET_KUNDLI';
export const SET_KUNDLI = 'SET_KUNDLI';
export const CREATE_KUNDLI = 'CREATE_KUNDLI';
export const DELETE_KUNDLI = 'DELETE_KUNDLI';

export const KUNDLI_GET_BIRTH_DETAIL = 'KUNDLI_GET_BIRTH_DETAIL';
export const KUNDLI_SET_BIRTH_DETAIL = 'KUNDLI_SET_BIRTH_DETAIL';

export const KUNDLI_GET_PANCHANG = 'KUNDLI_GET_PANCHANG';
export const KUNDLI_SET_PANCHANG = 'KUNDLI_SET_PANCHANG';

export const KUNDLI_GET_CHART = 'KUNDLI_GET_CHART';
export const KUNDLI_SET_CHART = 'KUNDLI_SET_CHART';

export const KUNDLI_GET_PLANET = 'KUNDLI_GET_PLANET';
export const KUNDLI_SET_PLANET = 'KUNDLI_SET_PLANET';

export const KUNDLI_GET_KP_PLANET = 'KUNDLI_GET_KP_PLANET';
export const KUNDLI_SET_KP_PLANET = 'KUNDLI_SET_KP_PLANET';

export const KUNDLI_GET_KP_HOUSE_CUP = 'KUNDLI_GET_KP_HOUSE_CUP';
export const KUNDLI_SET_KP_HOUSE_CUP = 'KUNDLI_SET_KP_HOUSE_CUP';

export const KUNDLI_GET_VIMSHOTTARI_DASHA = 'KUNDLI_GET_VIMSHOTTARI_DASHA';
export const KUNDLI_SET_VIMSHOTTARI_DASHA = 'KUNDLI_SET_VIMSHOTTARI_DASHA';

export const KUNDLI_GET_HOUSE_REPORT = 'KUNDLI_GET_HOUSE_REPORT';
export const KUNDLI_SET_HOUSE_REPORT = 'KUNDLI_SET_HOUSE_REPORT';

export const KUNDLI_GET_RASHI_REPORT = 'KUNDLI_GET_RASHI_REPORT';
export const KUNDLI_SET_RASHI_REPORT = 'KUNDLI_SET_RASHI_REPORT';

export const KUNDLI_GET_ASTAK_VARGA = 'KUNDLI_GET_ASTAK_VARGA';
export const KUNDLI_SET_ASTAK_VARGA = 'KUNDLI_SET_ASTAK_VARGA';

export const KUNDLI_GET_SARV_ASTAK = 'KUNDLI_GET_SARV_ASTAK';
export const KUNDLI_SET_SARV_ASTAK = 'KUNDLI_SET_SARV_ASTAK';

export const KUNDLI_GET_ASCENDENT = 'KUNDLI_GET_ASCENDENT';
export const KUNDLI_SET_ASCENDENT = 'KUNDLI_SET_ASCENDENT';

//! Ecommerce
export const GET_PRODUCT_CATEGORY = 'GET_PRODUCT_CATEGORY';
export const SET_PRODUCT_CATEGORY = 'SET_PRODUCT_CATEGORY';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const ADD_TO_CART = 'ADD_TO_CART';
export const GET_CART_DATA = 'GET_CART_DATA';
export const SET_CART_DATA = 'SET_CART_DATA';
export const UPDATE_CART_QUANTITY = 'UPDATE_CART_QUANTITY';
export const ORDER_CART = 'ORDER_CART';

//* This is for astrologer side UI
export const GET_CREATED_PUJA = 'GET_CREATED_PUJA';
export const SET_CREATED_PUJA = 'SET_CREATED_PUJA';
export const REGISTER_CREATED_PUJA = 'REGISTER_CREATED_PUJA';
//* This is for customer side UI
export const GET_APPROVED_CREATED_PUJA = 'GET_APPROVED_CREATED_PUJA';
export const SET_APPROVED_CREATED_PUJA = 'SET_APPROVED_CREATED_PUJA';
export const BOOK_APPROVED_CREATED_PUJA = 'BOOK_APPROVED_CREATED_PUJA';

//!Astromall
export const GET_ASTROMALL_DATA = 'GET_ASTRO_MALL_DATA'
export const SET_ASTROMALL_POOJA_DATA = 'SET_ASTROMALL_POOJA_DATA'
export const SET_ASTROMALL_SPELL_DATA = 'SET_ASTROMALL_SPELL_DATA'
export const GET_ASTROLOGER_POOJA_DATA = 'GET_ASTROLOGER_POOJA_DATA'
export const SET_ASTROLOGER_POOJA_DATA = 'SET_ASTROLOGER_POOJA_DATA'
export const ORDER_ASTROLOGER_POOJA = 'ORDER_ASTROLOGER_POOJA'
export const GET_ASTROMALL_HISTORY = 'GET_ASTROMALL_HISTORY'
export const SET_ASTROMALL_HISTORY = 'SET_ASTROMALL_HISTORY'

//! Blog
export const GET_ASTRO_BLOG_CATEGORY = 'GET_ASTRO_BLOG_CATEGORY';
export const SET_ASTRO_BLOG_CATEGORY = 'SET_ASTRO_BLOG_CATEGORY';
export const GET_ASTRO_BLOG = 'GET_ASTRO_BLOG';
export const SET_ASTRO_BLOG = 'SET_ASTRO_BLOG';
export const GET_RECENT_ASTRO_BLOG = 'GET_RECENT_ASTRO_BLOG';
export const SET_RECENT_ASTRO_BLOG = 'SET_RECENT_ASTRO_BLOG';
export const INCREMENT_ASTRO_BLOG_VIEW_COUNT = 'INCREMENT_ASTRO_BLOG_VIEW_COUNT';

//! Static Page
export const GET_TERMS_AND_CONDITION = 'GET_TERMS_AND_CONDITION';
export const SET_TERMS_AND_CONDITION = 'SET_TERMS_AND_CONDITION';
export const GET_PRIVACY_POLICY = 'GET_PRIVACY_POLICY';
export const SET_PRIVACY_POLICY = 'SET_PRIVACY_POLICY';

//! Profile
export const CREATE_KUNDLI_MATCHING_PROFILE = 'CREATE_KUNDLI_MATCHING_PROFILE';
export const GET_KUNDLI_MATCHING_PROFILE = 'GET_KUNDLI_MATCHING_PROFILE';
export const SET_KUNDLI_MATCHING_PROFILE = 'SET_KUNDLI_MATCHING_PROFILE';
export const GET_KUNDLI_MATCHING_PROFILE_BY_ID = 'GET_KUNDLI_MATCHING_PROFILE_BY_ID';
export const SET_KUNDLI_MATCHING_PROFILE_BY_ID = 'SET_KUNDLI_MATCHING_PROFILE_BY_ID';

//! Astrology API
//* Horoscope
export const GET_DAILY_HOROSCOPE = 'GET_DAILY_HOROSCOPE';
export const SET_DAILY_HOROSCOPE = 'SET_DAILY_HOROSCOPE';
export const GET_MONTHLY_HOROSCOPE = 'GET_MONTHLY_HOROSCOPE';
export const SET_MONTHLY_HOROSCOPE = 'SET_MONTHLY_HOROSCOPE';

//* Kundli Matching
export const GET_KUNDLI_MATCHING_BIRTH_DETAILS = 'GET_KUNDLI_MATCHING_BIRTH_DETAILS';
export const SET_KUNDLI_MATCHING_BIRTH_DETAILS = 'SET_KUNDLI_MATCHING_BIRTH_DETAILS';
export const GET_KUNDLI_MATCHING_ASTRO_DETAILS = 'GET_KUNDLI_MATCHING_ASTRO_DETAILS';
export const SET_KUNDLI_MATCHING_ASTRO_DETAILS = 'SET_KUNDLI_MATCHING_ASTRO_DETAILS';
export const GET_KUNDLI_MATCHING_ASHTAKOOT_POINTS_DETAILS = 'GET_KUNDLI_MATCHING_ASHTAKOOT_POINTS_DETAILS';
export const SET_KUNDLI_MATCHING_ASHTAKOOT_POINTS_DETAILS = 'SET_KUNDLI_MATCHING_ASHTAKOOT_POINTS_DETAILS';
export const GET_KUNDLI_MATCHING_DASHAKOOT_POINTS_DETAILS = 'GET_KUNDLI_MATCHING_DASHAKOOT_POINTS_DETAILS';
export const SET_KUNDLI_MATCHING_DASHAKOOT_POINTS_DETAILS = 'SET_KUNDLI_MATCHING_DASHAKOOT_POINTS_DETAILS';
export const GET_KUNDLI_MATCHING_MANGLIK_REPORT_DETAILS = 'GET_KUNDLI_MATCHING_MANGLIK_REPORT_DETAILS';
export const SET_KUNDLI_MATCHING_MANGLIK_REPORT_DETAILS = 'SET_KUNDLI_MATCHING_MANGLIK_REPORT_DETAILS';